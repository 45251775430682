@import '../../styles/utils.scss';

.el {
  width: 100%;
  position: relative;

  .button {
    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 26px 33px 21px;
      @include mobile {
        padding: 22px 24px 21px 27px;
      }
      @supports (-webkit-touch-callout: none) {
        pointer-events: none;
      }
    }
    select {
      display: none;
      @supports (-webkit-touch-callout: none) {
        display: inherit;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    color:$color-blue;
  }
  .icon {
    font-size: 12px;
    margin-left: 12px;
    margin-top: -2px;
    color: $color-blue-25;
    @include tablet {
      // padding: 0 6px;
    }
    &:before {
      display: block;
      transform: rotate(180deg);
    }
    &.open {
      &:before {
        transform: rotate(0);
      }
    }
  }
  .options {
    overflow: hidden;
    max-height: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    background: #fff;
    color: $color-blue;
    // transition: max-height 0.4s ease-in-out;
    @include tablet {
    }
    &.closed {
      animation: optionsClose 0.4s ease-in-out forwards;
    }
    &.active {
      // max-height: 250px;
      // overflow-y: auto;
      animation: optionsOpen 0.4s ease-in-out forwards;
      border: 1px solid $color-blue-25;
    }
    &Wrap {
      padding: 13px 0 3px;
    }
    .optionItem {
      padding: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 2.6;
      letter-spacing: 0.05em;
      text-align: center;
      &:hover, &.active {
        background: $color-blue-10;
      }
      button {
        width: 100%;
        padding: 9px;
      }
    }
  }
}

@keyframes optionsOpen {
  0% { max-height: 0; overflow: hidden; }
  99% { max-height: 250px; overflow: hidden; }
  100% { max-height: 250px; overflow-y: auto; }
}
@keyframes optionsClose {
  0% { max-height: 250px; overflow: auto; }
  1% { max-height: 250px; overflow: hidden; }
  100% { max-height: 0; overflow-y: hidden; }
}