@import '../../styles/utils.scss';

.el {
  .container {
    padding-bottom: 60px;
    max-width: $container-md;
  }
  .filters {
    margin-top: 80px;
    @include tablet {
      margin-top: 0;
    }
  }
  .filterSelectWrap {
    & + .filterSelectWrap {
      button {
        border-left: none;
      }
    }
  }
  .filtersMobile {
    display: none;
    justify-content: space-between;
    padding: 20px $mobile-padding;
    margin-left: -$mobile-padding;
    margin-right: -$mobile-padding;
    border-bottom: 1px solid $color-blue-25;
    @include tablet {
      display: flex;
    }
    button {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      color: $color-blue;
      span {
        font-size: 24px;
        margin-right: 10px;
        margin-bottom: 5px;
        &:before {
          display: block;
          margin-bottom: -4px;
        }
      }
    }
  }
  .filterHeadMobile {
    display: none;
    height: 60px;
    border-bottom: 1px solid $color-blue-25;
    padding: 0 $mobile-padding;
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 200;
      background: #fff;
    }
    h3 {
      line-height: 1.1;
    }
    .backBtn {
      font-size: 24px;
      margin-top: 8px;
      span {
        display: inline-block;
        transform: scaleX(-1);
      }
    }
    .closeBtn {
      font-size: 24px;
      margin-top: 8px;
    }
  }
  .filterBottomMobile {
    display: none;
    position: fixed;
    z-index: 101;
    background: #fff;
    width: 100%;
    left: 100%;
    bottom: 0;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 17vh;
    transition: left 0.5s;
    @include tablet {
      display: flex;
    }
    .applyBtn {
      background: $color-orange;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: #fff;
      padding: 15px 30px;
      min-width: 230px;
      border-radius: 24px;
      margin-bottom: 10px;
    }
  }
  .filtersWrapper {
    display: flex;
    @include tablet {
      // display: none;
      left: 100%;
      display: block;
      position: fixed;
      overflow: auto;
      z-index: 100;
      width: 100%;
      height: calc(100vh - 60px);
      // height: calc(var(--vh, 1vh) * 100 - 60px);
      background: #fff;
      top: 60px;
      padding: 0 0 calc(17vh + 60px) 0;
      @supports (-webkit-touch-callout: none) {
        padding: 0 0 calc(17vh + 112px) 0;
      }
      transition: left 0.5s;
      & > div {
        width: 100% !important;
        max-width: 100vw;
      }
      &.open {
        left: 0;
        .filterBottomMobile {
          left: 0;
        }
      }
    }
  }
  .selectedFilters {
    .filterButton {
      position: relative;
      background: $color-orange;
      color: #fff;
      border-radius: 19px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      padding: 10px 51px 10px 20px;
      display: inline-flex;
      justify-content: space-between;
      text-transform: uppercase;
      margin-right: 20px;
      margin-bottom: 10px;

      span.cross {
        position: absolute;
        right: 15px;
        top: 17px;
        &:before, &:after {
          background: #fff;
        }
      }
    }
    &:global(.mobile) {
      display: none;
      padding: 12px $mobile-padding;
      background: $color-grey;
      width: 100%;
    }
    @include tablet {
      &:global(.desktop) {
        display: none;
      }
      &:global(.mobile) {
        display: block;
      }
    }
  }
  .filtersSort {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 26px 0 24px;
    @include tablet {
      // justify-content: flex-end;
      // padding: 40px 0 20px;
      align-items: center;
    }
    .sortSelect {
      width: 200px;
      min-width: 140px;
      border: 1px solid #C9C9D7;
      @include mobile {
        width: 140px;
      }
    }
    & + .numberTeamMembers {
      margin-top: 0;
      @include tablet {
        margin-top: 0;
      }
    }
  }
  .numberTeamMembers {
    margin-top: 25px;
    padding-bottom: 23px;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tablet {
      margin-top: 40px;
      justify-content: space-between;
    }
    .number {
      text-decoration: underline;
    }
    .clearButton {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: $color-blue;
      text-transform: uppercase;
      white-space: nowrap;
      @include tablet {
        &:global(.desktop) {
          display: none;
        }
      }
      span {
        display: block;
        font-size: 24px;
        line-height: 16px;
        // margin-bottom: -2px;
        margin-right: 10px;
      }
    }
    &Mobile {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      padding-right: 15px;
      .number {
        text-decoration: underline;
      }
    }
  }
  .peopleWrapper {
    margin-left: -18px;
    margin-right: -18px;
  }
  span.cross {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 12px;
    height: 2px;
    &:before, &:after {
      content: ' ';
      display: block;
      width: 12px;
      height: 2px;
      background: #fff;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .peopleCard {
    display: inline-block;
    vertical-align: top;
    margin: 0 18px 40px;
    width: calc(25% - 36px);
    @include tablet {
      margin: 0 15px 40px;
      width: calc(33.3% - 30px);
    }
    @include mobile {
      margin: 0 10px 40px;
      width: calc(50% - 20px);
    }
  }
  .noResults {
    padding: 50px 18px 100px;
    font-size: 36px;
  }
  :global(.mobile) {
    display: none;
  }
  @include tablet {
    :global(.desktop) {
      display: none;
    }
    :global(.mobile) {
      display: block;
    }
  }
}

.iconClose{
  font-size:14px;
  color: $color-blue;
 font-weight: bold;
 line-height: 120%;
 vertical-align:middle;
 text-transform: uppercase;
}
