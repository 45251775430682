@import '../../styles/utils.scss';

.el {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border: 1px solid $color-blue-25;
  @include mobile {
    justify-content: space-between;
    border-left: none;
  }
  & > * {
    border-left: 1px solid $color-blue-25;
  }
  .pageTotal {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    padding: 26px 33px 21px;
    @include mobile {
      padding: 22px 10px 21px;
      flex-grow: 3;
      text-align: center;
    }
  }
  .button {
    font-size: 24px;
    color: $color-orange;
    padding: 20px 20px 10px;
    @include mobile {
      padding: 20px 17px 10px;
    }
    span {
      display: block;
    }
    &.disabled {
      pointer-events: none;
      span {
        opacity: 0.25;
      }
    }
  }
  .prevButton {
    span {
      transform: scaleX(-1);
    }
  }
}