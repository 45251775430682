@import '../../styles/utils.scss';

.el {
  width: 100%;
  .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s;
    }
    &:before {
      content: ' ';
      display: inline-block;
      width: 100%;
      padding-top: 133.44%;
    }
    &Wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: all 0.3s;
    }
  }
  .content {
    .name {
      color: $color-blue;
      margin-top: 15px;
      @include mobile {
        line-height: 1.1;
      }
    }
    .title {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
      color: $color-blue;
      @include mobile {
        font-size: 14px;
      }
    }
  }
  &:hover {
    .image {
      img {
        transform: translate(-50%, -50%) scale(1.1);
      }
      &Wrap {
        height: calc(100% - 40px);
      }
    }
  }
}
