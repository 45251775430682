@import '../../styles/utils.scss';

.el {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: $container-fhd;
    margin: 0 auto;
    @include mobile {
        display: block;
    }
    .content {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: 1px solid $color-blue-25;
        @include mobile {
            width: 100%;
            border: none;
        }
        .container {
            max-width: calc($container-md / 2);
            margin: 0;
            .contentWrapper {
                width: 80%;
                @include tablet {
                    width: 100%;
                }
            }
        }
    }
    .title {
    }
    .description {
        margin-top: 15px;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: -0.03em;
    }
    .image {
        position: relative;
        width: 50%;
        padding-top: 50%;
        background-color: #e5e5e5;
        background-size: cover;
        background-position: center;
        font-size: 0;
        line-height: 0;
        @media screen and (min-width: 1800px) {
            padding-top: 900px;
        }
        @include mobile {
            width: 100%;
            padding-top: 100%;
            margin-top: 38px;
        }
        .overlay {
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0.8;
            width: 83%;
            max-width: 740px;
            svg {
                width: 100%;
                height: auto;
            }
        }
        .playButton {
            position: absolute;
            cursor: pointer;
            top: 0%;
            left: 0;
            text-align: center;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
